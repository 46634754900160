import styles from './styles.module.css';
import Image from "@/shared/components/Image/new"; //esto viene de destacada, importar img
import { formatAuthorNames } from '@/shared/functions/formatAthorNames'


const DestacadoEspecial = (props) => {
    
    const {
        title,
        subtitle,
        text,
        url,
        url_img_destacada,
        texto_cabezal,
        url_cabezal,
        url_link_cabezal,
        isPreview,
        summary, 
        image, 
        author,
        showAuthor = true
        } = props;
    let authorsList = []
	if (showAuthor && (author != undefined && author?.length)) {
		const authorName = formatAuthorNames(author[0].name);
		author.length > 1 ? author.slice(0, 2).map(element => {

			const elementName = formatAuthorNames(element.name);
			return authorsList.push(element.slug ? `<a href=/autor/${element.slug}>${elementName}</a>` : elementName)

		}) 
		
		: authorsList.push(author[0].slug ? `<a href=/autor/${author[0].slug}>${authorName}</a>` : authorName)
	}
	const parsedAuthors = authorsList.join(' y ');   
    let img_destacada = "";
    if(url_img_destacada != "" ) img_destacada = <Image	src={url_img_destacada} width='100%' />;
        else img_destacada = <Image	src={image} width='100%' />;


     let cabezal = "";
     if(url_cabezal != "" ) cabezal = <Image	src={url_cabezal} height='25' />;
     if (texto_cabezal != "" && url_cabezal=="") cabezal = <div className={styles['texto-destacado-especial-nota']}>{texto_cabezal}</div>;
       
     return (
       
          <div className={isPreview ? styles['preview-containter-destacado-especial-nota'] : styles['container-destacado-especial-nota']}> 
         
         <div className={styles['center-destacado-especial-nota']} >
         <a target='_blank' rel='nofollow' href={url_link_cabezal != undefined && url_link_cabezal?.length ? url_link_cabezal:""} >
         {cabezal}
         </a>
         <div className={styles['destacado-especial-sponsor']} ><a target='_blank' rel='nofollow' href={url_link_cabezal != undefined && url_link_cabezal?.length ? url_link_cabezal:""} >
         </a></div>
       <a href={url} >
					
                    <h1>
                                        <strong>
                                            {subtitle}{" "}
                                        </strong>
                                        {title}
                    </h1>   </a> </div>               
                    <div className={styles['caja2']}>  <a href={url} >	{summary != "" && <div className={styles["bajada-nota-destacado-especial"]} dangerouslySetInnerHTML={{ __html: summary }} />}</a>
					

{parsedAuthors && <div className={styles["author"]} dangerouslySetInnerHTML={{ __html: 'Por ' + parsedAuthors }} />}
<div className={styles['compartir-destacado-especial']} ><a href={'https://api.whatsapp.com/send?text=Accede%20a%20esta%20noticia%20de%20El%20Litoral:%20'+url+'%3Futm_medium%3Dwhatsapp%20-%20Envianos%20tu%20noticia%20a:%20%2B5493426305344'} ><img src="https://www.ellitoral.com/collections/utiles-diseno/destacado-especial-whatsapp___661fc44e3ef70b001c44f211.jpg" ></img></a>

<a href={`http://twitter.com/share?url=https://www.ellitoral.com${url}`} target="_blank" rel='noreferrer noopener'><img src="https://www.ellitoral.com/collections/utiles-diseno/destacado-especial-x___661fc0d43ef70b001c44f210.jpg" ></img></a>

<a href={`https://www.facebook.com/sharer.php?u=https://www.ellitoral.com${url}`} target="_blank" rel='noreferrer noopener'><img src="https://www.ellitoral.com/collections/utiles-diseno/destacado-especial-facebook___661fc0d33ef70b001c44f20f.jpg" ></img></a>

</div>



</div>

         
          <div className={styles['embebed-destacado-especial-nota']}>
              {img_destacada}
          </div>             
      </div>
     )
}
export default DestacadoEspecial;
